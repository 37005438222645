import React, { Component } from 'react';
import './draggable-menu.css';

import { TweenMax, TimelineMax } from "gsap";

import Helpers, { MathUtils } from './../../classes/helpers';
import ImageGrid from './image-grid/image-grid';
import MenuItem from './menu-item/menu-item';
import Cursor from './cursor/cursor';

// import Draggabilly from 'draggabilly';
if (typeof window !== `undefined`) {
	var Draggabilly = require('draggabilly');
}

class draggableMenu extends Component {

	constructor(props) {
		// console.log("draggableMenu :: constructor");
		super(props);
		this.state = {
			dataItems: props.decades,
			leftEdge: false,
			rightEdge: false
		}

		this.domMenu = React.createRef();
		this.domGridWrap = React.createRef();
		this.domCursor = React.createRef();
		this.menuItems = [];
		this.imageGrids = [];
		this.DOM = {};

	}

	componentDidMount = () => {
		// console.log("draggableMenu :: componentDidMount");

		// Bind window resize event
		window.addEventListener('resize', Helpers.getWinsize);

		// The menu wrap (.menu-wrap)
		this.DOM.el = this.domMenu.current;
		// The menu element
		this.DOM.menu = this.DOM.el.querySelector('.menu');
		// The draggable container
		this.DOM.draggable = this.DOM.el.querySelector('.menu-draggable');
		// Content wrap
		this.DOM.pagePreview = document.querySelector('.page--preview');
		// The ctrl that closes the grid view and shows back the menu
		this.DOM.backToMenuCtrl = this.DOM.pagePreview.querySelector('.gridback');

		// The image grids (one per menu item)
		// this.imageGrids = [];
		// [...this.DOM.pagePreview.querySelectorAll('.grid')].forEach(item => this.imageGrids.push(new ImageGrid(item)));
		// MenuItem instances
		// this.menuItems = [];
		// [...this.DOM.menu.querySelectorAll('.menu__item')].forEach((item, position) => this.menuItems.push(new MenuItem(item, this.imageGrids[position])));

		this.state.dataItems.map((item, index) => {
			this.menuItems[index].imageGrid = this.imageGrids[index];
		});

		// Total number of menu items
		this.menuItemsTotal = this.menuItems.length;
		// Index of the current menuItem
		this.current = this.menuItemsTotal - 1;
		// Set the current menu item and show its explore link
		this.menuItems[this.current].setCurrent().showExplore();
		// Show the current grid items
		this.menuItems[this.current].imageGrid.showImages();

		// Initialize the Draggabilly (on the x axis)
		if (typeof window !== `undefined`) {
			// browser code
			this.draggie = new Draggabilly(this.DOM.draggable, { axis: 'x' });
		}

		// The current amount (in pixels) that was dragged
		this.dragPosition = 0;
		// Minimum amount to drag in order to navigate to the next/previous menu item
		this.minDrag = Helpers.winSize.width * .04;
		// Set the menu initial position
		this.layout();
		// The following are the values that need to be updated inside the render (rAF) function: 
		// - the menu translation value 
		// - the letters/spans (stroke and filled) translation values
		// - and the grid images opacity and transform values
		// The "current" and the "previous" hold the values to interpolate ("current" being the one we want to get to) and the "amt" is the amount to interpolate
		this.renderedStyles = {
			menuTranslation: { previous: this.dragPosition + this.initTx, current: this.dragPosition + this.initTx, amt: 0.1 },
			letterTranslation: { previous: 0, current: 0, amt: 0.1 },
			imgOpacity: { previous: 1, current: 1, amt: 0.1 },
			imgScaleX: { previous: 1, current: 1, amt: 0.06 },
			imgScaleY: { previous: 1, current: 1, amt: 0.06 },
			imgTranslation: { previous: 0, current: 0, amt: 0.1 }
		};
		// Start the rAF loop to render the menu and letters positions
		this.renderId = requestAnimationFrame(() => this.rendered());

		// Initialize/Bind some events
		this.initEvents();

		// Show/Hide navigation arrow depending on which side of menu ends
		this.menuEdge = "right";
		this.showHideNavigation();

		// Create ref for cursor
		this.cursor = this.domCursor.current;
		// Activate the enter/leave/click methods of the custom cursor when hovering in/out on every <a> and the back to menu ctrl
		[...document.querySelectorAll('a'), document.querySelector('button')].forEach((link) => {
			link.addEventListener('mouseenter', () => this.cursor.enter());
			link.addEventListener('mouseleave', () => this.cursor.leave());
		});

	}
	layout() {
		// console.log("draggableMenu :: layout");
		// Set the menu position/translation so that the first/last menu item is the current one thus positioned at the center
		// We need to save these values for later calculations when translating the menu
		// this.initTx = this.currentPosition = Helpers.winSize.width / 2 - this.menuItems[this.current].rect.width / 2;
		this.initTx = this.currentPosition = Helpers.winSize.width / 2 - this.DOM.menu.offsetWidth + this.menuItems[this.current].rect.width / 2;
		TweenMax.set(this.DOM.menu, { x: this.initTx });
	}
	// Window resize
	resize() {
		// console.log("draggableMenu :: resize");
		// Helpers.getWinsize();

		this.minDrag = Helpers.winSize.width * .04;
		// Update position
		try {
			this.currentPosition = Helpers.winSize.width / 2 - this.menuItems[this.current].DOM.el.offsetLeft - this.menuItems[this.current].rect.width / 2;
			this.renderedStyles.menuTranslation.current = this.renderedStyles.menuTranslation.previous = this.currentPosition;
		} catch (error) {
			console.log(error);
		}
	}
	isDragging() {
		// dragDirection is only set when we drag the menu, so this can be used to checked if we are currently dragging
		return this.dragDirection !== undefined && this.dragDirection !== '';
	}
	rendered() {
		this.renderId = undefined;

		// Apply the lerp function to the updated values
		for (const key in this.renderedStyles) {
			this.renderedStyles[key].previous = MathUtils.lerp(this.renderedStyles[key].previous, this.renderedStyles[key].current, this.renderedStyles[key].amt);
		}

		// Translate the menu
		TweenMax.set(this.DOM.menu, { x: this.renderedStyles.menuTranslation.previous });

		// Switch the filled spans with stroke ones and vice versa
		// Also update the grid images
		if (this.isDragging() && this.currentItem && this.upcomingItem) {
			let tx = this.renderedStyles.letterTranslation.previous;
			TweenMax.set(this.currentItem.letters, { x: this.dragDirection === 'left' ? -1 * tx - 100 + '%' : tx - 100 + '%' });
			TweenMax.set(this.upcomingItem.letters, { x: this.dragDirection === 'left' ? tx + '%' : -1 * tx + '%' });

			TweenMax.set(this.currentItem.imageGrid.DOM.images, {
				transformOrigin: this.dragDirection === 'left' ? '100% 50%' : '0% 50%',
				opacity: this.renderedStyles.imgOpacity.previous,
				scaleX: this.renderedStyles.imgScaleX.previous,
				scaleY: this.renderedStyles.imgScaleY.previous,
				x: this.dragDirection === 'left' ? -1 * this.renderedStyles.imgTranslation.previous + '%' : this.renderedStyles.imgTranslation.previous + '%'
			});
			TweenMax.set(this.upcomingItem.imageGrid.DOM.images, {
				transformOrigin: this.dragDirection === 'left' ? '0% 50%' : '100% 50%',
				opacity: Math.abs(1 - this.renderedStyles.imgOpacity.previous),
				scaleX: 3 - this.renderedStyles.imgScaleX.previous,
				scaleY: 1.8 - this.renderedStyles.imgScaleY.previous,
				x: this.dragDirection === 'left' ? 150 - this.renderedStyles.imgTranslation.previous + '%' : -1 * (150 - this.renderedStyles.imgTranslation.previous) + '%'
			});
		}

		if (!this.renderId) {
			this.renderId = requestAnimationFrame(() => this.rendered());
		}
	}
	initEvents() {
		this.onPointerDown = () => {
			// Scale up the cursor
			this.cursor.renderedStyles['scale'].current = 1.5;
			// And show the "drag mode" arrows
			this.cursor.showArrows();
		};

		this.onDragStart = () => {
			// console.log("onDragStart");
			if (this.isAnimating) return;
			// Reset the drag direction value
			this.dragDirection = '';
		};

		// Save the previous moveVector obj that Draggability provides for every drag move
		// We need this to track the current direction of dragging in order to compare it later with the initial intended direction
		// Like so we know if the menu should navigate to the next/previous item or if the navigation needs to be cancelled
		this.cachedVectorMovement = { x: 0, y: 0 };
		this.onDragMove = (event, pointer, moveVector) => {
			// Update the mouse position
			Helpers.mousePos = Helpers.getMousePos(event);

			// Return if theres an active animation
			if (this.isAnimating) return;

			// Track the current direction of the drag
			if (moveVector.x !== this.cachedVectorMovement.x) {
				this.currentDirection = moveVector.x > this.cachedVectorMovement.x ? 'right' : 'left';
				this.cachedVectorMovement = moveVector;
			}

			if (this.dragDirection === '') {

				// The initial intended direction
				this.dragDirection = moveVector.x > 0 ? 'right' : 'left';

				// We need to calculate the amount to move the menu as we drag from one point of the screen to another.
				// If we are switching between two menu items then this value is the distance from the center of the current menu item to the center of the next or previous menuItem (depending on the dragging direction)
				// otherwise it will be the same as this.minDrag so that when we stop dragging the navigation gets cancelled

				// Hide the explorer link
				this.menuItems[this.current].hideExplore();

				// Boundary cases
				if ((this.dragDirection === 'right' && this.current === 0) || (this.dragDirection === 'left' && this.current === this.menuItemsTotal - 1)) {
					this.amountToMove = this.minDrag;
				}
				// else move to the next/previous menuItem
				else {
					this.upcomingIdx = this.dragDirection === 'left' ? this.current + 1 : this.current - 1;
					this.currentItem = this.menuItems[this.current];
					this.upcomingItem = this.menuItems[this.upcomingIdx];
					this.amountToMove = Math.abs((this.currentItem.rect.left + this.currentItem.rect.width / 2) - (this.upcomingItem.rect.left + this.upcomingItem.rect.width / 2));
				}
			}

			if (this.draggie !== undefined) {
				this.updateMenuTransition();
			}
		};

		this.onPointerUp = () => {
			// Scale down the cursor (reset)
			this.cursor.renderedStyles['scale'].current = 1;
			// And hide the "drag mode" arrows
			this.cursor.hideArrows();
		};

		this.onDragEnd = () => {
			if (!this.isAnimating) {
				this.isAnimating = true;

				// Cancel the render function (rAF) 
				if (this.renderId) {
					window.cancelAnimationFrame(this.renderId);
					this.renderId = undefined;
				}

				// Cancel the navigation:
				// Either it didn't drag enough (<= minDrag) or the drag direction changed to the opposite one, meaning the user stepped back from navigating or it is an end of navigation
				if (Math.abs(this.dragPosition) <= this.minDrag || this.dragDirection !== this.currentDirection || this.current === this.upcomingIdx) {
					// Show the explore link
					this.menuItems[this.current].showExplore();

					// Reset the rAF updated values
					this.renderedStyles.menuTranslation.current = this.renderedStyles.menuTranslation.previous = this.currentPosition;
					this.renderedStyles.letterTranslation.current = this.renderedStyles.letterTranslation.previous = 0;
					this.renderedStyles.imgOpacity.current = this.renderedStyles.imgOpacity.previous = 1;
					this.renderedStyles.imgScaleX.current = this.renderedStyles.imgScaleX.previous = 1;
					this.renderedStyles.imgScaleY.current = this.renderedStyles.imgScaleY.previous = 1;
					this.renderedStyles.imgTranslation.current = this.renderedStyles.imgTranslation.previous = 0;

					const tl = new TimelineMax({
						onComplete: () => {
							// Restart the rAF loop
							this.renderId = requestAnimationFrame(() => this.rendered());
							// Reset values..
							this.currentItem = undefined;
							this.upcomingItem = undefined;
							// Able to drag and animate again
							this.isAnimating = false;
						}
					})
						// Animate the menu back to the previous position
						.to(this.DOM.menu, Helpers.ANIMATION_SETTINGS.menu.duration, {
							ease: Helpers.ANIMATION_SETTINGS.menu.ease,
							x: this.currentPosition
						}, 0);

					// Reset the letters translations and grid images
					if (this.currentItem && this.upcomingItem) {
						tl
							.to(this.currentItem.letters, Helpers.ANIMATION_SETTINGS.letters.duration, {
								ease: Helpers.ANIMATION_SETTINGS.letters.ease,
								x: '-100%'
							}, 0)
							.to(this.upcomingItem.letters, Helpers.ANIMATION_SETTINGS.letters.duration, {
								ease: Helpers.ANIMATION_SETTINGS.letters.ease,
								x: '0%'
							}, 0)
							.to(this.currentItem.imageGrid.DOM.images, Helpers.ANIMATION_SETTINGS.images.duration, {
								ease: Helpers.ANIMATION_SETTINGS.images.ease,
								opacity: 1,
								scaleX: 1,
								scaleY: 1,
								x: '0%'
							}, 0)
							.to(this.upcomingItem.imageGrid.DOM.images, Helpers.ANIMATION_SETTINGS.images.duration, {
								ease: Helpers.ANIMATION_SETTINGS.images.ease,
								opacity: 0,
								scaleX: 2,
								scaleY: 0.8,
								x: this.dragDirection === 'left' ? '150%' : '-150%'
							}, 0);
					}
				}
				// Move to the next/previous menu item
				else {
					this.animateMenu();
				}
			}

			if (this.draggie !== undefined) {
				// Reset the drag position value
				this.dragPosition = 0;
				this.draggie.setPosition(this.dragPosition, this.draggie.position.y);

				// Reset the drag direction value
				this.dragDirection = '';
			}
		}

		// Draggabily events
		if (this.draggie !== undefined) {
			this.draggie.on('pointerDown', this.onPointerDown);
			this.draggie.on('dragStart', this.onDragStart);
			this.draggie.on('dragMove', this.onDragMove);
			this.draggie.on('pointerUp', this.onPointerUp);
			this.draggie.on('dragEnd', this.onDragEnd);
		}

		// Clicking the explore opens up the grid for the current menu item
		for (let menuItem of this.menuItems) {
			menuItem.DOM.explore.addEventListener('click', () => this.showContent());
		}

		// Back to menu from grid view
		this.DOM.backToMenuCtrl.addEventListener('click', () => this.hideContent());

		// Resize window: update menu position
		window.addEventListener('resize', () => this.resize());
	}

	updateMenuTransition() {
		// Update the dragPosition:
		// We need to map the draggable movement ([0,winsize.width]) to the menu movement ([0,amountToMove])
		this.dragPosition = MathUtils.lineEq(this.amountToMove, 0, Helpers.winSize.width, 0, this.draggie.position.x);

		// Finally update both the menu translation, letters translation and grid images (rAF render function)
		this.renderedStyles.menuTranslation.current = this.dragPosition + this.currentPosition;
		this.renderedStyles.letterTranslation.current = MathUtils.lineEq(100, 0, Helpers.winSize.width, 0, this.dragDirection === 'left' ? Math.min(this.draggie.position.x, 0) : Math.max(this.draggie.position.x, 0));
		this.renderedStyles.imgOpacity.current = MathUtils.lineEq(0, 1, Helpers.winSize.width, 0, this.dragDirection === 'left' ? Math.abs(Math.min(this.draggie.position.x, 0)) : Math.abs(Math.max(this.draggie.position.x, 0)));
		this.renderedStyles.imgScaleX.current = MathUtils.lineEq(2, 1, Helpers.winSize.width, 0, this.dragDirection === 'left' ? Math.abs(Math.min(this.draggie.position.x, 0)) : Math.abs(Math.max(this.draggie.position.x, 0)));
		this.renderedStyles.imgScaleY.current = MathUtils.lineEq(0.8, 1, Helpers.winSize.width, 0, this.dragDirection === 'left' ? Math.abs(Math.min(this.draggie.position.x, 0)) : Math.abs(Math.max(this.draggie.position.x, 0)));
		this.renderedStyles.imgTranslation.current = MathUtils.lineEq(150, 0, Helpers.winSize.width, 0, this.dragDirection === 'left' ? Math.abs(Math.min(this.draggie.position.x, 0)) : Math.abs(Math.max(this.draggie.position.x, 0)));
	}

	animateMenu() {
		if (typeof this.currentItem !== `undefined`) {

			// Show the explore link
			this.menuItems[this.upcomingIdx].showExplore();

			// Set the updated menu translation value
			this.currentPosition
				= this.renderedStyles.menuTranslation.current = this.renderedStyles.menuTranslation.previous
				= this.dragDirection === 'left' ?
					this.currentPosition - this.amountToMove :
					this.currentPosition + this.amountToMove;

			// Reset letters translation value
			this.renderedStyles.letterTranslation.current = this.renderedStyles.letterTranslation.previous = 0;

			// Reset grid images values
			this.renderedStyles.imgOpacity.current = this.renderedStyles.imgOpacity.previous = 1;
			this.renderedStyles.imgScaleX.current = this.renderedStyles.imgScaleX.previous = 1;
			this.renderedStyles.imgScaleY.current = this.renderedStyles.imgScaleY.previous = 1;
			this.renderedStyles.imgTranslation.current = this.renderedStyles.imgTranslation.previous = 0;

			const tl = new TimelineMax({
				onComplete: () => {
					// Restart the rAF loop
					this.renderId = requestAnimationFrame(() => this.rendered());
					// Update the menu item current state
					this.currentItem.unsetCurrent();
					this.upcomingItem.setCurrent();
					// Update the current item index value
					this.current = this.upcomingIdx;
					// Reset values.. 
					this.currentItem = undefined;
					this.upcomingItem = undefined;
					// Able to drag and animate again
					this.isAnimating = false;

					this.showHideNavigation();
				}
			})
				// Animate the menu translation
				.to(this.DOM.menu, Helpers.ANIMATION_SETTINGS.menu.duration, {
					ease: Helpers.ANIMATION_SETTINGS.menu.ease,
					x: this.currentPosition
				}, 0)
				// Animate the letters (current item gets stroke letters while the previous current item gets filled, thus the translation needs to be set differently for the current and upcoming item)
				.to(this.currentItem.letters, Helpers.ANIMATION_SETTINGS.letters.duration, {
					ease: Helpers.ANIMATION_SETTINGS.letters.ease,
					x: '0%'
				}, 0)
				.to(this.upcomingItem.letters, Helpers.ANIMATION_SETTINGS.letters.duration, {
					ease: Helpers.ANIMATION_SETTINGS.letters.ease,
					x: '-100%'
				}, 0)
				// And animate the grid images
				.to(this.currentItem.imageGrid.DOM.images, Helpers.ANIMATION_SETTINGS.images.duration, {
					ease: Helpers.ANIMATION_SETTINGS.images.ease,
					opacity: 0,
					scaleX: 2,
					scaleY: 0.8,
					x: this.dragDirection === 'left' ? '-150%' : '150%'
				}, 0)
				.to(this.upcomingItem.imageGrid.DOM.images, Helpers.ANIMATION_SETTINGS.images.duration, {
					ease: Helpers.ANIMATION_SETTINGS.images.ease,
					opacity: 1,
					scaleX: 1,
					scaleY: 1,
					x: '0%'
				}, 0);
		}
	}

	handleNavigation(navDirection) {

		// Return if theres an active animation
		if (this.isAnimating) return;

		// Check if menu not at the end of the edge
		if (!((this.menuEdge === "left" && navDirection === "left") || (this.menuEdge === "right" && navDirection === "right"))) {

			// Navigation direction is the opposite of drag direction
			if (navDirection === "left") {
				this.dragDirection = "Right";
			} else {
				this.dragDirection = "left";
			}

			// Hide the explorer link
			this.menuItems[this.current].hideExplore();

			// move to the next/previous menuItem
			this.upcomingIdx = this.dragDirection === 'left' ? this.current + 1 : this.current - 1;
			this.currentItem = this.menuItems[this.current];
			this.upcomingItem = this.menuItems[this.upcomingIdx];
			this.amountToMove = Math.abs((this.currentItem.rect.left + this.currentItem.rect.width / 2) - (this.upcomingItem.rect.left + this.upcomingItem.rect.width / 2));

			this.updateMenuTransition();

			if (!this.isAnimating) {
				this.isAnimating = true;

				// Cancel the render function (rAF) 
				if (this.renderId) {
					window.cancelAnimationFrame(this.renderId);
					this.renderId = undefined;
				}

				TweenMax.set(this.upcomingItem.imageGrid.DOM.images, {
					transformOrigin: this.dragDirection === 'left' ? '0% 50%' : '100% 50%',
					opacity: Math.abs(1 - this.renderedStyles.imgOpacity.previous),
					scaleX: 3 - this.renderedStyles.imgScaleX.previous,
					scaleY: 1.8 - this.renderedStyles.imgScaleY.previous,
					x: this.dragDirection === 'left' ? 150 - this.renderedStyles.imgTranslation.previous + '%' : -1 * (150 - this.renderedStyles.imgTranslation.previous) + '%'
				});

				this.animateMenu();
			}
		}
	}

	showHideNavigation() {
		if (this.current + 1 <= 1) {
			this.menuEdge = "left";
			this.setState({
				leftEdge: true
			});
		} else if (this.current + 1 >= this.menuItems.length) {
			this.menuEdge = "right";
			this.setState({
				rightEdge: true
			});
		} else {
			this.menuEdge = "none";
			this.setState({
				leftEdge: false,
				rightEdge: false
			});
		}

		// Reset the drag direction value
		this.dragDirection = '';
	}

	showBackCtrl() {
		return this.toggleBackCtrl('show');
	}
	hideBackCtrl() {
		return this.toggleBackCtrl('hide');
	}
	toggleBackCtrl(action = 'show') {
		return new Promise((resolve, reject) => {
			TweenMax.to(this.DOM.backToMenuCtrl, Helpers.ANIMATION_SETTINGS.backCtrl.duration, {
				ease: Helpers.ANIMATION_SETTINGS.backCtrl.ease,
				startAt: action === 'hide' ? null : { x: '100%' },
				opacity: action === 'hide' ? 0 : 1,
				x: action === 'hide' ? '-100%' : '0%',
				onComplete: resolve
			});
		});
	}
	showContent() {
		if (this.isAnimating) return;
		this.isAnimating = true;

		// Cancel the render function (rAF) 
		if (this.renderId) {
			window.cancelAnimationFrame(this.renderId);
			this.renderId = undefined;
		}

		// Remove this class so we see a scrollable area now
		this.DOM.pagePreview.classList.remove('page--preview');

		let promises = [];
		// Reset the transforms of the grid items forming again the original grid
		promises.push(this.menuItems[this.current].imageGrid.collapse());
		// Hide the explore link
		promises.push(this.menuItems[this.current].hideExplore());
		// Slide menu items letters out
		for (let item of this.menuItems) {
			promises.push(item.hide());
		}
		// Show back control
		promises.push(this.showBackCtrl());

		Promise.all(promises).then(() => this.isAnimating = false);
	}
	hideContent() {
		if (this.isAnimating) return;
		this.isAnimating = true;

		// First scroll to the top
		this.scrollIt(0, 300, 'easeOutQuad', () => {
			// Add this class to disable scrolling
			this.DOM.pagePreview.classList.add('page--preview');

			// Restart the rAF loop
			this.renderId = requestAnimationFrame(() => this.rendered());

			let promises = [];
			// Spread the grid items forming again the original grid
			promises.push(this.menuItems[this.current].imageGrid.spread(true));
			// Show the explore link
			promises.push(this.menuItems[this.current].showExplore());
			// Slide menu items letters in
			for (let item of this.menuItems) {
				promises.push(item.show());
			}
			// Hide back control
			promises.push(this.hideBackCtrl());

			Promise.all(promises).then(() => {
				this.isAnimating = false;
			});
		});
	}


	// https://pawelgrzybek.com/page-scroll-in-vanilla-javascript/
	scrollIt(destination, duration = 200, easing = 'linear', callback) {
		const easings = {
			linear(t) {
				return t;
			},
			easeOutQuad(t) {
				return t * (2 - t);
			},
		};

		const start = window.pageYOffset;
		const startTime = 'now' in window.performance ? performance.now() : new Date().getTime();

		const documentHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);
		const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName('body')[0].clientHeight;
		const destinationOffset = typeof destination === 'number' ? destination : destination.offsetTop;
		const destinationOffsetToScroll = Math.round(documentHeight - destinationOffset < windowHeight ? documentHeight - windowHeight : destinationOffset);

		if ('requestAnimationFrame' in window === false) {
			window.scroll(0, destinationOffsetToScroll);
			if (callback) {
				callback();
			}
			return;
		}

		function scroll() {
			const now = 'now' in window.performance ? performance.now() : new Date().getTime();
			const time = Math.min(1, ((now - startTime) / duration));
			const timeFunction = easings[easing](time);
			window.scroll(0, Math.abs(Math.ceil((timeFunction * (destinationOffsetToScroll - start)) + start)));
			if (window.pageYOffset === destinationOffsetToScroll) {
				if (callback) {
					callback();
				}
				return;
			}

			requestAnimationFrame(scroll);
		}

		scroll();
	}

	render() {
		const leftNavClass = this.state.leftEdge ? "nav-control nav-left hidden" : "nav-control nav-left";
		const rightNavClass = this.state.rightEdge ? "nav-control nav-right hidden" : "nav-control nav-right";

		return (
			<>
				<main className="dm-main">
					<div className="page page--preview">
						<div className="grid-wrap" ref={this.domGridWrap}>
							{
								this.state.dataItems.map((item, index) => {
									return (
										<ImageGrid
											key={index}
											ref={(ref) => { this.imageGrids[index] = ref; return true; }}
											posts={item.posts}>
										</ImageGrid>
									);
								})
							}
							<button className="gridback"><svg width="27px" height="15px" viewBox="0 0 27 15"><path d="M1.469 6.75l-.719.719 7.938 6.937.718-.719L1.47 6.75zM8.594.531L.75 7.375l.688.688L9.28 1.218 8.594.53zM1.406 6.938v1h24.75v-1H1.406z" fill="#7fb7bf" /></svg></button>
						</div>
					</div>
					<div className="menu-wrap" ref={this.domMenu}>
						<div className="menu-draggable"></div>
						<nav className="menu">
							{
								this.state.dataItems.map((item, index) => {
									return (
										<MenuItem
											key={index}
											ref={(ref) => { this.menuItems[index] = ref; return true; }}
											firstPost={item.first_article}
										>
											{item.decade}
										</MenuItem>
									);
								})
							}
						</nav>
					</div>
					<div className="nav-menu">
						<a className={leftNavClass} onClick={() => this.handleNavigation("left")}>
							<span className="sr-only">Previous</span>
						</a>
						<a className={rightNavClass} onClick={() => this.handleNavigation("right")}>
							<span className="sr-only">Next</span>
						</a>
						{/* {console.log("Left Edge:", this.state.leftEdge, ", Right Edge:", this.state.rightEdge)} */}
					</div>
				</main>
				<Cursor ref={this.domCursor} />
			</>
		)
	}
}

export default draggableMenu;
