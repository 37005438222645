import React, { Component } from 'react';
import { TweenMax } from "gsap";
import './image-grid.css';

import Helpers, { MathUtils } from "../../../classes/helpers";

class imageGrid extends Component {

	constructor(props) {
		// console.log("imageGrid :: constructor");
		super(props);
		this.state = {}

		this.refImgGrid = React.createRef();
		this.DOM = {};

		// console.log(props);
	}

	componentDidMount = () => {
		// console.log("imageGrid :: componentDidMount");
		// console.log(window.screenTop);
		Helpers.getWinsize();

		this.DOM.el = this.refImgGrid.current;
		this.DOM.imageWrap = [...this.DOM.el.querySelectorAll('.grid__item-wrap')];
		this.itemsTotal = this.DOM.imageWrap.length;
		this.DOM.images = [...this.DOM.el.querySelectorAll('.grid__item')];

		// Spread the grid items
		setTimeout(() => {
			this.spread();
		}, 600);
	}

	// Spreads the grid items by randomly positioning them and scaling them down
	spread(animate = false) {
		return new Promise((resolve, reject) => {
			let animateCount = 0;
			const gridHeight = this.DOM.el.scrollHeight;
			const gridTop = this.DOM.el.offsetTop;

			let q = 0;
			this.DOM.imageWrap.forEach((item, index) => {
				const rect = item.getBoundingClientRect();
				// console.log(rect);

				// Item's center point
				const center = {
					x: rect.left + rect.width / 2,
					y: rect.top + rect.height / 2
				};

				// Calculate the item's quadrant in the viewport
				// const quadrant = center.x >= Helpers.winSize.width / 2 ?
				// 	center.y <= gridHeight / 2 + gridTop ? 1 : 4 :
				// 	center.y <= gridHeight / 2 + gridTop ? 2 : 3;
				// console.log("quadrant:", quadrant);

				// New logic to calculate the item's quadrant in the viewport
				// to spread images in all parts of the screen equally
				if (index == 0) {
					// Assign random number to quadrant
					q = parseInt(MathUtils.getRandomInt(1, 4));
				} else {
					// rotate quadrant number from 1 to 4
					q += 1;
					if (q > 4) {
						q = 1;
					}
				}
				const quadrant = q;

				// Now calculate how much to translate the item
				// The positions will be random but only in the area of the item's quadrant
				// Also, consider a margin so the item does not stay completely out of the viewport or its quadrant
				const margins = { x: Helpers.winSize.width * .02, y: Helpers.winSize.height * .04 }
				const tx = quadrant === 1 || quadrant === 4 ?
					MathUtils.getRandomFloat(-1 * center.x + Helpers.winSize.width / 2 + margins.x * 4, Helpers.winSize.width - center.x - margins.x) :
					MathUtils.getRandomFloat(-1 * center.x + margins.x, Helpers.winSize.width / 2 - center.x - margins.x * 4);
				const ty = quadrant === 1 || quadrant === 2 ?
					MathUtils.getRandomFloat(-1 * center.y + margins.y, Helpers.winSize.height / 2 - center.y - margins.y * 4) :
					MathUtils.getRandomFloat(-1 * center.y + Helpers.winSize.height / 2 + margins.y * 4, Helpers.winSize.height - center.y - margins.y);

				// Save the current translation
				item.dataset.ctx = tx;
				item.dataset.cty = ty;

				// console.log(item.dataset.ctx, item.dataset.cty);

				if (animate) {
					TweenMax.to(item, Helpers.ANIMATION_SETTINGS.grid.duration, {
						ease: Helpers.ANIMATION_SETTINGS.grid.ease,
						x: tx,
						y: ty,
						scale: 0.75,
						onComplete: () => {
							++animateCount;
							if (animateCount === this.itemsTotal) {
								resolve();
							}
						}
					});
				}
				else {
					TweenMax.set(item, {
						x: tx,
						y: ty,
						scale: 0.75
					});
					resolve();
				}
			});
		});
	}
	// Resets the items to the original position (forming again the original grid)
	collapse() {
		return new Promise((resolve, reject) => {
			TweenMax.to(this.DOM.imageWrap, Helpers.ANIMATION_SETTINGS.grid.duration, {
				ease: Helpers.ANIMATION_SETTINGS.grid.ease,
				x: 0,
				y: 0,
				scale: 1.01,
				onComplete: resolve
			});
		});
	}
	showImages() {
		TweenMax.set(this.DOM.images, { opacity: 1 });
	}

	render() {
		return (
			<div className="grid" ref={this.refImgGrid}>
				{
					this.props.posts.map((post, i) => {
						let imgUrl = '';
						if (post.image !== null && post.image !== undefined) {
							imgUrl = post.image.source_url;
							return (
								<div className="grid__item-wrap" key={i}>
									<div className="grid__item" style={{ backgroundImage: "url(" + imgUrl + ")" }}></div>
								</div>
							);
						} else return;
					})
				}
			</div>
		);
	}
}

export default imageGrid;
