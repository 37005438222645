import React from 'react';
import Layout from "../components/layout";
import SEO from "../components/seo";
import DraggableMenu from './../components/draggable-menu/draggable-menu';
// import { useQuery } from '@apollo/react-hooks';
// import gql from 'graphql-tag';
import { useStaticQuery, graphql } from "gatsby"

// import { data } from "./../json-data/data";

// const HOME_POSTS_DECADES = gql`
//   {
// 		allWordpressAstraeaPostsDecades {
// 			nodes {
// 				decades {
// 					decade
// 					first_article {
// 						article_year
// 						post_title
// 						post_name
// 						thumbnail {
// 							source_url
// 						}
// 						image {
// 							source_url
// 						}
// 					}
// 					posts {
// 						article_year
// 						post_title
// 						post_name
// 						thumbnail {
// 							source_url
// 						}
// 						image {
// 							source_url
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}
// `;

// const HOME_TIMELINE_QUERY = gql`
//   {  
//     posts(first: 100, where: {is_featured: 1}) {
//       edges {
//         node {
//           id
//           uri
//           status
//           title
//           frontline_articles_options {
//             articleTemplate
//             articleYear
//             articleTitlePosition
//             backgroundColor
//             backgroundImage {
//               sourceUrl
//             }
//             featuredImage {
//               sourceUrl
//             }
//             yearPositionWithTheImage
//             yearPositionWithTheTimeline
//             yearSizeOverTheTimeline
//             imagePositionOverTimeline
//           }
//         }
//       }
//     }
//   }
// `;

const IndexPage = () => {
  // const { loading, error, data } = useQuery(HOME_TIMELINE_QUERY);

  const data = useStaticQuery(graphql`
    {
      allWordpressAstraeaPostsDecades {
        nodes {
          decades {
            decade
            first_article {
              post_title
              post_name
              article_year
              thumbnail {
                source_url
              }
							image {
            		source_url
          		}
              url
            }
            posts {
              post_title
              post_name
              article_year
              post_date
              thumbnail {
                source_url
              }
							image {
            		source_url
          		}
            }
          }
        }
      }
    }
  `)

  // console.log(useQuery(HOME_POSTS_DECADES));

  const decades = data.allWordpressAstraeaPostsDecades.nodes[0].decades;
  console.log(decades);

  return (
    <Layout animatedHeader="animatedHeader">
      <SEO title="Home" />
      <div className="home-globe-bg"></div>
      <div className="timeline-wrapper">
        <DraggableMenu decades={decades} />
      </div>
    </Layout>
  )
}

export default IndexPage
