import React, { Component } from 'react';
import { TweenMax } from "gsap";
import './cursor.css';

import Helpers, { MathUtils } from "../../../classes/helpers";

class cursor extends Component {

	constructor(props) {
		// console.log("cursor :: constructor");
		super(props);
		this.state = {}

		this.refCursor = React.createRef();
		this.DOM = {};

	}

	componentDidMount = () => {
		// console.log("cursor :: componentDidMount");
		Helpers.mousePos = { x: Helpers.winSize.width / 2, y: Helpers.winSize.height / 2 };
		// Track the mouse position
		window.addEventListener('mousemove', ev => Helpers.mousePos = Helpers.getMousePos(ev));
		window.addEventListener('touchstart', ev => Helpers.mousePos = Helpers.getMousePos(ev));

		this.DOM.el = this.refCursor.current;
		this.DOM.circle = this.DOM.el.querySelector('.cursor__inner--circle');
		this.DOM.arrows = {
			right: this.DOM.el.querySelector('.cursor__side--right'),
			left: this.DOM.el.querySelector('.cursor__side--left')
		};
		this.bounds = this.DOM.circle.getBoundingClientRect();

		this.renderedStyles = {
			tx: { previous: 0, current: 0, amt: 0.2 },
			ty: { previous: 0, current: 0, amt: 0.2 },
			scale: { previous: 1, current: 1, amt: 0.2 }
		};
		requestAnimationFrame(() => this.rendered());
	}

	rendered() {
		this.renderedStyles['tx'].current = Helpers.mousePos.x - this.bounds.width / 2;
		this.renderedStyles['ty'].current = Helpers.mousePos.y - this.bounds.height / 2;

		for (const key in this.renderedStyles) {
			this.renderedStyles[key].previous = MathUtils.lerp(this.renderedStyles[key].previous, this.renderedStyles[key].current, this.renderedStyles[key].amt);
		}

		this.DOM.circle.style.transform = `translateX(${(this.renderedStyles['tx'].previous)}px) translateY(${this.renderedStyles['ty'].previous}px) scale(${this.renderedStyles['scale'].previous})`;
		requestAnimationFrame(() => this.rendered());
	}
	enter() {
		this.renderedStyles['scale'].current = 1.9;
	}
	leave() {
		this.renderedStyles['scale'].current = 1;
	}
	click() {
		this.renderedStyles['scale'].previous = 0.4;
	}
	showArrows() {
		TweenMax.to(Object.values(this.DOM.arrows), Helpers.ANIMATION_SETTINGS.cursor.duration, {
			ease: Helpers.ANIMATION_SETTINGS.cursor.ease,
			startAt: { x: i => i ? 10 : -10 },
			opacity: 1,
			x: 0
		});
	}
	hideArrows() {
		TweenMax.to(Object.values(this.DOM.arrows), Helpers.ANIMATION_SETTINGS.cursor.duration, {
			ease: Helpers.ANIMATION_SETTINGS.cursor.ease,
			x: i => i ? 10 : -10,
			opacity: 0
		});
	}

	render() {
		return (
			<div className="cursor" ref={this.refCursor}>
				<div className="cursor__inner cursor__inner--circle">
					<div className="cursor__side cursor__side--left"></div>
					<div className="cursor__side cursor__side--right"></div>
				</div>
			</div>
		);
	}
}

export default cursor;
