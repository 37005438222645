import React, { Component } from 'react';
import { TweenMax } from "gsap";
import Charming from 'react-charming';

import TimelineArticleImgAnim from "../../timelineArticleImgAnim";
import Helpers from "../../../classes/helpers";

class menuItem extends Component {

	constructor(props, imageGrid) {
		// console.log("menuItem :: constructor");
		super(props);
		this.state = {}

		this.refMenuItem = React.createRef();
		this.DOM = {};
		this.rect = {};
	}

	componentDidMount = () => {
		// console.log("menuItem :: componentDidMount");
		// The Menu item wrapper
		this.DOM.el = this.refMenuItem.current;
		// The inner link (.menu__item-link)
		this.DOM.link = this.DOM.el.querySelector('.menu__item-link');
		// The explore link
		this.DOM.explore = this.DOM.el.querySelector('.menu__item-explore');
		this.DOM.exploreLink = this.DOM.el.querySelector('.menu__item-explore-link');
		this.DOM.note = this.DOM.el.querySelector('.menu__item-note');
		// We will need the size and position for the calculations needed to drag/translate the menu
		this.rect = this.DOM.el.getBoundingClientRect();

		// The images grid for this menu item
		// this.imageGrid = imageGrid;

		// As we drag, the letters will switch from only stroke to filled and vice versa
		// We need to split the letters into spans and create the necessary structure (we will have two spans per letter, one for the stroke version and one for the filled)
		// charming(this.DOM.link, { classPrefix: false });

		const linkInner = [...this.DOM.link.querySelectorAll('span')];
		linkInner.forEach((span) => {
			const stroke = span.cloneNode(true);
			span.classList.add('letter__inner', 'letter__inner--filled');
			stroke.classList.add('letter__inner', 'letter__inner--stroke');
			const ltr = this.DOM.link.querySelector('.ltr')
			ltr.insertBefore(stroke, span.nextSibling);
			const letter = document.createElement('span');
			letter.classList = 'letter';
			letter.appendChild(span);
			letter.appendChild(stroke);
			this.DOM.link.appendChild(letter);
		});
		this.letters = [...this.DOM.link.querySelectorAll('.letter__inner')];
		// Need to recalculate size and position on window resize
		window.addEventListener('resize', () => this.rect = this.DOM.el.getBoundingClientRect());
	}
	// getAlert() {
	//   console.log("Menu Item:", this.props.children);
	// }
	setCurrent() {
		this.DOM.el.classList.add('menu__item--current');
		return this;
	}
	unsetCurrent() {
		this.DOM.el.classList.remove('menu__item--current');
	}
	isCurrent() {
		return this.DOM.el.classList.contains('menu__item--current');
	}

	// Show/Hide the explore link 
	showExplore() {
		return this.toggleExplorer('show');
	}
	hideExplore() {
		return this.toggleExplorer('hide');
	}
	toggleExplorer(action = 'show') {
		return new Promise((resolve, reject) => {
			TweenMax.to(this.DOM.explore, Helpers.ANIMATION_SETTINGS.explore.duration, {
				ease: Helpers.ANIMATION_SETTINGS.explore.ease,
				startAt: action === 'hide' ? null : { scale: 0.5 },
				opacity: action === 'hide' ? 0 : 1,
				scale: action === 'hide' ? 0.8 : 1,
				onComplete: resolve
			});
			TweenMax.to(this.DOM.exploreLink, Helpers.ANIMATION_SETTINGS.explore.duration, {
				ease: Helpers.ANIMATION_SETTINGS.explore.ease,
				startAt: action === 'hide' ? null : { scale: 0.5 },
				opacity: action === 'hide' ? 0 : 1,
				scale: action === 'hide' ? 0.8 : 1,
				onComplete: resolve
			});
			TweenMax.to(this.DOM.note, Helpers.ANIMATION_SETTINGS.explore.duration, {
				ease: Helpers.ANIMATION_SETTINGS.explore.ease,
				startAt: action === 'hide' ? null : { scale: 0.5 },
				opacity: action === 'hide' ? 0 : 1,
				scale: action === 'hide' ? 0.8 : 1,
				onComplete: resolve
			});
		});
	}
	// Show/Hide the letters
	show() {
		return this.toggle('show');
	}
	hide() {
		return this.toggle('hide');
	}
	toggle(action = 'show') {
		return new Promise((resolve, reject) => {
			const tx = action === 'hide' ? this.isCurrent() ? '-200%' : '100%' : this.isCurrent() ? '-100%' : '0%';
			TweenMax.to(this.letters, Helpers.ANIMATION_SETTINGS.allMenuLettersToggle.duration, {
				ease: Helpers.ANIMATION_SETTINGS.allMenuLettersToggle.ease,
				x: tx,
				onComplete: resolve
			});
		});
	}

	render() {
		let menuText = '';
		if (this.props.children !== null) {
			menuText = this.props.children.toString() + "s";
		}

		console.log(this.props);
		let timelineArticleImgAnimLink;
		if (this.props.firstPost !== null) {
			const firstPost = this.props.firstPost;
			let imgUrl = '';
			if (firstPost.image !== null && firstPost.image !== undefined) {
				imgUrl = firstPost.image.source_url;
			}
			
			timelineArticleImgAnimLink = (
				<TimelineArticleImgAnim
					cover
					className="menu__item-explore-link slide-article-img-cover"
					duration={2}
					to={`${firstPost.url}`}
					// style={{ backgroundImage: `url(https://frontlineapi.astraeafoundation.org/app/uploads/2019/12/Astraea-National-Lesbian-Action-Foundation-Big-Sign.jpg)` }}
					bg={`url(${imgUrl}) center / cover no-repeat`}
				>
					Explore Decade
				</TimelineArticleImgAnim>
			)
		} else {
			timelineArticleImgAnimLink = (
				<TimelineArticleImgAnim
					cover
					className="menu__item-explore-link slide-article-img-cover"
					duration={2}
					to="/"
					bg={`url() center / cover no-repeat`}
				>
					Explore Decade
				</TimelineArticleImgAnim>
			)
		}
		return (
			<div className="menu__item" ref={this.refMenuItem}>
				<a className="menu__item-link">
					<Charming className="ltr" letters={menuText} />
					{/* {menuText} */}
				</a>
				<a className="menu__item-explore hidden">Explore Gallery</a>
				{timelineArticleImgAnimLink}
				{/* <a className="menu__item-explore-link">Explore Decade</a> */}
				{/* <Link className="menu__item-explore-link" to="/first-grants">Explore Decade</Link> */}
				<div className="menu__item-note">Click and drag to explore other decades</div>
			</div>
		);
	}
}

export default menuItem;
